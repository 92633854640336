.projects {
	//background-image: url('../img/background/groovepaper.png');
	text-align:center;
	//.transition { transition: .3s cubic-bezier(.3, 0, 0, 1.3) }
	.sec-title {
		color: #2385a1;
		padding-top: 15px;
	}

	article {
		padding-bottom: 20px;
		margin-top: 50px;
	}
	h3 {
		margin-top: 0px;
	}
	.project-img {
		img {
			margin-left: auto;
			margin-right: auto;
			// max-width: 400px;
			max-height: 300px;
		}
	}

}

.project-link {
	margin-top: 15px;
	text-align: center;
	a {
		font-size: 20px;
	}
}

.interactive-card {
	margin-top: 100px;
	display: inline-block;
	margin-left: 10px;
	overflow: hidden;
	transition: 0.3s cubic-bezier(0.3, 0, 0, 1.3);
	max-width: 261px;
	width: 100%;
	height: 215px;
	//max-height: 177px;
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
	//padding: 200px;
	background-color: white;
	// &:hover {
	// 	height: 450px;
	// }
	h2 { 
		color: #2385a1;
		text-align: center;
		font-family: 'Raleway', sans-serif;
		font-size: 19px;
		font-weight: 200;
		transition: 0.3s cubic-bezier(0.3, 0, 0, 1.3);
	}
	.image-circle {
		margin-top: -112px;
		margin-left: -75px;
		border-radius: 50%;
		background-color: #2385A1;
		background-image: url('../img/act-tech.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 400px;
		height: 194px;
		//border-radius: 50%;
	}
	.project-description {
		padding: 10px;
	}
	.project-details-btn {
		text-align: center;
		//visibility: hidden;
		display: none;
		a, button {

			border-radius: 2px;
			background-clip: padding-box;
			background-color: rgba(255, 255, 255, 0);
			border: 2px solid #2385A1;
			color: #2385A1;
			display: inline-block;
			font-family: 'Raleway', sans-serif;
			font-size: 17px;
			font-weight: 400;
			height: 36px;
		    letter-spacing: 0.5px;
		    line-height: 36px;
		    margin-bottom: 15px;
		    padding: 0 2rem;
		    text-decoration: none;
		    text-transform: uppercase;
		    transition: 0.2s ease-out;
		    &:hover {
		    	background-color: #2385A1;
		    	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		    	color: #fff;
		    }
		}
	}	
	&:hover{
		margin-top: -100px;
		.image-circle {
			margin-top:0px;
			margin-left: 0px;
			border-radius: 0;
			width: 261px;
		}
		height: 325px;
		h2 {
			background-color: #2385A1;
			color: #FFF;
			transition: 0.3s cubic-bezier(0.3, 0, 0, 1.3);
			margin-top: -100px;
			margin-bottom: 115px;
						
		}
		.project-details-btn {
			display: block;
		}

		.project-description {
			margin-top: -34px;
		}		
	}	
}

.wireframe {
  margin-top: 70px;
  h1, h2 {
  	color: #2385A1;
  }
  h1 {
  	margin-bottom: 70px;
  }
  img {
  	margin-left: auto;
  	margin-right: auto;
  	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
  }

  .frames {
  	margin-bottom: 70px;
  }
}

#contact {
	padding: 24px;
	color: #2385A1;
}