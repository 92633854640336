

.navbar-default {
    //background-color: #FFF;
    background-color:rgba(255, 255, 255, 0);
    border-color: rgba(231, 231, 231, 0);
    transition: .3s;
//     padding-bottom: 14px;
	@media (max-width: 785px) and (min-width: 767px) {
		font-size: 13px;
	} 


	@media (max-width: 768px) {
		background-color: #2385a1;
		color: white;
	}
	a.navbar-brand {
		@media (max-width: 768px) and (min-width: 768px) {
			color: #ffffff;
		} 
	}
    .navbar-nav > li > a {
    	text-transform: uppercase;
    	color: #2385a1;
    	text-align: center;
		@media (max-width: 768px) and (min-width: 768px) {
			color: #ffffff;
		} 	    	
    	@media (max-width: 767px) {
    		color: #FFFFFF;
    	}
    }
}

.navbar-header {
	.navbar-toggle {
		color: #2385a1;
		// border-color: #2385a1;
		border: 1px solid #2385a1;
		transition: .2s;
		@media (max-width: 767px) {
			border: 1px solid #ffffff;
			color: #ffffff;
		}
		&:hover, &:focus {
			background-color: #2385a1;
			color: #ffffff;
			@media (max-width: 767px) {
				background-color: #ffffff;
				color: #2385a1;
			}
		}
	}
	.navbar-brand {
		font-family: 'Courgette', cursive;
		color: #2385a1;
		transition: .2s;
		@media (max-width: 767px) {
			color: #FFFFFF;
		}
		&.inverted-colors {
			transition: .2s;
			color: #FFF;			
		}
	}
}

.navbar-toggle.inverted-colors {
	transition: .2s;
	color: #FFF;
	border: 1px solid #FFF;
}

// transition class
.scroll-nav {
	transition: .3s ease-out;
	background-color: rgb(35, 133, 161);
	.navbar-nav > li > a {
		color: white;
	}
	
}