
.about {
	//background-image: url('../img/background/groovepaper.png');
	background-color: #2385a1;
	color: #fff;
	padding: 10px;
// 	background-blend-mode: color-burn;
	p {
		font-size: 28px;
		font-family: 'Jura', sans-serif;
		//font-family: 'Raleway', sans-serif;
	}

	.container {
		@media (max-width: 780px) {
			width: 90%;
		}		
	}
}


