@import 'https://fonts.googleapis.com/css?family=Jura';
@import 'http://fonts.googleapis.com/css?family=Raleway:500';
@import 'https://fonts.googleapis.com/css?family=Courgette';

// bootstrap row reset
.row {
	margin-left: 0px;
	margin-right: 0px;
}

body {
	 background-image: url('../img/background/groovepaper.png');                 
}
.content {
	margin-top: 61px;
}

hr {
	margin: 0;


}



#skill-set {

	h1 {
		margin-bottom: 45px;
		@media (min-width: 768px) {
			opacity: 0;			
		}
	}
	#web {
		@media (min-width: 768px) {
			opacity: 0;			
		}
	}
	#software {
		@media (min-width: 768px) {
			opacity: 0;			
		}
	}
	#other {
		@media (min-width: 768px) {
			opacity: 0;			
		}
	}	
}

.modal-content {
	background-image: url(../img/background/groovepaper.png);
	.modal-body {
	    //color: white;
	    //background-color: #2385a1;
	    position: relative;
	    padding: 15px;
	    .project-img {
	    	box-shadow: 0 0 20px rgba(0, 0, 0, 0.68);
	    	//border: 1px solid white;
	    	overflow: hidden;
	    	border-radius: 2px;
	    }
	}	
	.close-btn {
		text-align: center;
		margin-bottom: 10px;
		button {
			font-size: 19px;
			width: 200px;
			color: #fff;
			background-color: #2385a1;
			border-color: #2385a1
		}		
	}

		
}

.laptop {
	max-width: 500px;
	padding-top: 52px;
	margin-left: auto;
	margin-right: auto;	
	&-img {

		padding-top: 16px;
		padding-left: 5px;
		//width: 600px;
		//outline: 1px solid black;
		height: 600px;
		background-repeat: no-repeat;
		background-position-y: 0px;
		//background-size: 500px;
		background-size:contain;
		@media (max-width: 510px) {
			background-image: none !important;
		}
		&-screen {
			font-family: Raleway;
			color: #2385a1;
			background-color: black;
			outline: 1px solid white;;
			//margin-top: 12px;
			width: 80%;
			margin-left: auto;
			margin-right: auto;
			height: auto;
			@media (max-width: 510px) {
				border-radius: 5px;
				padding: 10px;
				width: 90%;
			}
			h2 {
				padding-top: 16px;
				font-size: 35px;
			}
			p {
				font-size: 30px;
				margin-bottom: 0;
			}
			.pulse-btn {
				text-align: center;
				i {
					color: #000000;
					animation-iteration-count: infinite;
				}
			}
		}

	}
	img {
		visibility: hidden;
	}
}

.container {
	@media (min-width: 768px) {
		width: 90%;
	}
}

#contact {
	h1 {
		@media (max-width: 400px) {
			font-size: 20px;
		}
		
	}
}
