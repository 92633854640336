@import 'nav';
@import 'header';
@import 'skillset';
@import 'projects';
@import 'about';

.modal {
	top: 58px;
	.modal-dialog {
		@media (min-width: 991px) {
			width: 826px;
		}
	}
}