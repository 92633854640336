

#skill-set {

	h1 {
		color: #2385a1;
	}

	.fa {
		margin-bottom: 40px;
		color: #2385a1;
	}
	.list-group-item {
		font-family: 'Raleway', sans-serif; 
		background-color: #2385a10a;
		transition: .2s;
		border: 1px solid #2385a1;
		color: #2385a1;
		&:hover, &:focus {
			background-color: #2385a1;
			color: #fff;
			transition: .2s;
		}
	}
}